import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DistributorTile } from './components/DistributorTile';
import { PageWithHeaderLayout } from '../../common/layout/PageWithHeaderLayout/PageWithHeaderLayout';
import { PlanTile } from './components/PlanTile';
import { usePricingPlans } from '../../hooks/usePricingPlans';
import { useDistributors } from '../../hooks/useDistributors';
import { DistributorModel } from '../../api/models/distributors';
import {
  PricingPlanCategories,
  PricingPlanModel,
  TOURISM_INDUSTRY,
} from '../../api/models/pricingPlans';
import { useAffiliates } from '../../hooks/useAffiliates';
import { AffiliateModel } from '../../api/models/affiliates';
import { TileList } from './components/TileList';
import { useAppDispatch, useTypedSelector } from '../../store';
import { UserRoles } from '../../common/constants/constants';
import { stringToBase64 } from '../../services/utilities';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { PlansEventNames } from '../../common/constants/events/plansEvents';
import { getPricingPlanCategories } from '../../store/slices/pricingPlansSlice';
// import { PricingScreenStrings } from '../../common/localization/en';

const DefaultImageUrl =
  'https://storage.googleapis.com/sv-dev-assets/all/07a24cc7-4a1d-4136-8c28-d6546d31ede5.svg';

interface SignUpFormParams {
  promo?: string;
  dist?: string;
  muid?: string;
  industry?: string;
}

interface PlansPageProps {
  signUpRoute: string;
}

export enum EnterprisePLanStrings {
  EnterPriseId = 'ENTERPRISE',
  EnterPriseName = 'Enterprise',
  SVEnterpriseName = 'SocialV All',
  SVTravelEnterpriseName = 'SocialV Travel',
  SVDMO = 'SocialV DMO',
  monthlyFee = '$-',
  setupFee = '$-',
  distributorId = '',
  code = 'ENTERPRISE',
  TOURISMCATEGORY = 'SV Travel',
}

export const SVenterPrisePlan = {
  id: `SV-${EnterprisePLanStrings.EnterPriseId}`,
  name: `${EnterprisePLanStrings.SVEnterpriseName}`,
  code: `SV-${EnterprisePLanStrings.code}`,
  distributorId: EnterprisePLanStrings.distributorId,
  monthlyFee: EnterprisePLanStrings.monthlyFee,
  setupFee: EnterprisePLanStrings.setupFee,
  subscriptionFee: '0',
  billingPeriod: '0',
  position: 100,
};
export const SVTravelenterPrisePlan = {
  id: `SV-Travel${EnterprisePLanStrings.EnterPriseId}`,
  name: `${EnterprisePLanStrings.SVDMO}`,
  code: `SV-${EnterprisePLanStrings.code}`,
  distributorId: EnterprisePLanStrings.distributorId,
  monthlyFee: EnterprisePLanStrings.monthlyFee,
  setupFee: EnterprisePLanStrings.setupFee,
  subscriptionFee: '0',
  billingPeriod: '0',
};
export const SVDMOEnterprisePlan = {
  id: `SV-Travel-dmo${EnterprisePLanStrings.EnterPriseId}`,
  name: `${EnterprisePLanStrings.SVTravelEnterpriseName}`,
  code: `SV-${EnterprisePLanStrings.code}`,
  distributorId: EnterprisePLanStrings.distributorId,
  monthlyFee: EnterprisePLanStrings.monthlyFee,
  setupFee: EnterprisePLanStrings.setupFee,
  subscriptionFee: '0',
  billingPeriod: '0',
};
export const TBenterPrisePlan = {
  id: `TB-${EnterprisePLanStrings.EnterPriseId}`,
  name: `TB-${EnterprisePLanStrings.EnterPriseName}`,
  code: `TB-${EnterprisePLanStrings.code}`,
  distributorId: EnterprisePLanStrings.distributorId,
  monthlyFee: EnterprisePLanStrings.monthlyFee,
  setupFee: EnterprisePLanStrings.setupFee,
};
export const SVHealthenterPrisePlan = {
  id: `SVH-${EnterprisePLanStrings.EnterPriseId}`,
  name: `SocialV Health`,
  code: `SVH-${EnterprisePLanStrings.code}`,
  distributorId: EnterprisePLanStrings.distributorId,
  monthlyFee: EnterprisePLanStrings.monthlyFee,
  setupFee: EnterprisePLanStrings.setupFee,
};

export const PlansPage: React.FC<PlansPageProps> = ({ signUpRoute }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [selectedDistributor, setSelectedDistributor] = useState<DistributorModel['id']>();
  const [selectedCategory, setSelectedCategory] = useState<PricingPlanCategories['id']>();
  const [selectedPlan, setSelectedPlan] = useState<PricingPlanModel['id']>();
  const [selectedAffiliate, setSelectedAffiliate] = useState<AffiliateModel['id']>();
  const { categories } = useTypedSelector((state) => state.pricingPlans);
  const { role, id } = useTypedSelector((state) => state.me);
  const isSVAdmin = role === UserRoles.admin;
  const showCopyIcon = isSVAdmin;
  const { trackEvent } = useTrackEvent();
  const {
    distributors,
    isDistributorsLoading,
    activeDistributor,
    getDistributorById,
  } = useDistributors();

  const sortedDistributors = useMemo(() => {
    if (activeDistributor) {
      // keep only active distributor
      return [activeDistributor];
    }
    const result = [...distributors]
      .sort((d1, d2) => d2.name.localeCompare(d1.name))
      .filter((d) => d.name.toLocaleLowerCase() !== 'test'); // remove test distributors
    //set sv as first item
    const svIndex = result.findIndex(
      (distributor) => distributor.name.toLocaleLowerCase() === 'sv',
    );

    if (svIndex > 0) {
      const firstItem = result[0];
      result[0] = result[svIndex];
      result[svIndex] = firstItem;
    }

    return result;
  }, [activeDistributor, distributors]);

  const isTestDistributor =
    distributors.find((d) => d.id === selectedDistributor)?.code.toLocaleLowerCase() === '_testsv';
  const isSVHDistributor =
    distributors.find((d) => d.id === selectedDistributor)?.code.toLocaleLowerCase() === 'svh01';
  const isTBDistributor =
    distributors.find((d) => d.id === selectedDistributor)?.code.toLocaleLowerCase() === 'b360';
  //SV-ALL Enterprise
  // SV-Travel Enterprise
  console.log('isSVHDistributor', isSVHDistributor);
  console.log('selectedDistributor', selectedDistributor, distributors);
  const enterPrisePlan = isSVHDistributor
    ? SVHealthenterPrisePlan
    : isTBDistributor
    ? TBenterPrisePlan
    : SVenterPrisePlan;
  console.log('enterPrisePlan', enterPrisePlan);
  const { pricingPlans, fetchPricingPlans, isPricingPlansLoading, getPlanById } = usePricingPlans();
  const { affiliates, isAffiliatesLoading, getAffiliateById, activeAffiliate } = useAffiliates();

  const enterprisePlans = [
    SVenterPrisePlan,
    SVHealthenterPrisePlan,
    TBenterPrisePlan,
    SVTravelenterPrisePlan,
    SVDMOEnterprisePlan,
  ];

  useEffect(() => {
    dispatch(getPricingPlanCategories());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (categories.length) {
      setSelectedCategory(categories[0].id);
    }
  }, [categories]);

  useEffect(() => {
    if (activeAffiliate) {
      setSelectedAffiliate(activeAffiliate.id);
    }
  }, [activeAffiliate]);

  useEffect(() => {
    if (activeDistributor) {
      setSelectedDistributor(activeDistributor.id);
    }
  }, [activeDistributor]);

  const currentDistributorPricingPlans = useMemo(() => {
    return pricingPlans
      .filter(({ distributorId }) => distributorId === selectedDistributor)
      .sort((p1, p2) => p1.position - p2.position);
  }, [pricingPlans, selectedDistributor]);

  const currentCategoryPricingPlans = useMemo(() => {
    return pricingPlans
      .filter(({ categoryId }) => categoryId === selectedCategory)
      .sort((p1, p2) => p1.position - p2.position);
  }, [pricingPlans, selectedCategory]);

  // Get affiliates with associated pricing-plans
  const currentDistributorAffiliates = useMemo(() => {
    return affiliates
      .filter(({ distributorId }) => distributorId === selectedDistributor)
      .map((affiliate) => ({
        ...affiliate,
        plan: pricingPlans.find((pricingPlan) => pricingPlan.id === affiliate.planId),
      }));
  }, [affiliates, pricingPlans, selectedDistributor]);

  useEffect(() => {
    if (categories && categories.length) {
      fetchPricingPlans();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  // Select distributor by default
  useEffect(() => {
    if (!selectedDistributor && sortedDistributors.length > 0) {
      setSelectedDistributor(sortedDistributors[0].id);
    }
  }, [selectedDistributor, sortedDistributors]);

  const resetPlansAffiliatesState = () => {
    setSelectedAffiliate(undefined);
    setSelectedPlan(undefined);
  };

  const canGoToSignUp = !!selectedAffiliate || !!selectedPlan;

  const isTravelAndTourism = selectedCategory
    ? categories.find((item) => item.id === selectedCategory)?.industry === TOURISM_INDUSTRY
    : false;

  const getSearchParams = (
    planId: string | undefined,
    selectedPlan: string | undefined,
    isTourismIndustry?: boolean,
  ) => {
    if (!planId) {
      console.error('Plan not found');
      return;
    }
    const enterprisePlan = selectedPlan && enterprisePlans.find((elem) => selectedPlan === elem.id);
    const promo = selectedPlan
      ? enterprisePlan
        ? enterprisePlan.code
        : getPlanById(planId)?.code
      : selectedAffiliate
      ? getAffiliateById(planId)?.code
      : getDistributorById(planId)?.code;

    if (!promo) {
      console.error('Promo code not found');
      return;
    }
    const isTravelAndTourism = isTourismIndustry
      ? isTourismIndustry
      : selectedCategory
      ? categories.find((item) => item.id === selectedCategory)?.industry === TOURISM_INDUSTRY
      : false;
    const isTrubluPlan =
      distributors.find((distr) => distr.id === selectedDistributor)?.name === 'SocialSmiles';

    const promoCode =
      promo === EnterprisePLanStrings.code && isTrubluPlan
        ? `TB-${EnterprisePLanStrings.code}`
        : promo;
    const distributorCode = distributors.find((distr) => distr.id === selectedDistributor)?.code;
    const params: SignUpFormParams = distributorCode
      ? {
          promo: stringToBase64(promoCode),
          muid: stringToBase64(id),
          dist: stringToBase64(distributorCode),
          industry: isTravelAndTourism ? stringToBase64(TOURISM_INDUSTRY) : stringToBase64(''),
        }
      : {
          promo: stringToBase64(promoCode),
          muid: stringToBase64(id),
          industry: isTravelAndTourism ? stringToBase64(TOURISM_INDUSTRY) : stringToBase64(''),
        };
    return params;
  };

  const getPlanLinkUrl = (planId: string, isTourism?: boolean) => {
    const params = isTourism
      ? getSearchParams(planId, planId, true)
      : getSearchParams(planId, planId);
    return `${window.location.origin}${signUpRoute}?${new URLSearchParams(
      (params as unknown) as Record<string, string>,
    ).toString()}`;
  };
  const handleCopyToClipboard = (planId: string) => {
    const isTourism = planId.toLowerCase().includes('travel');
    const data = getPlanLinkUrl(planId, isTourism);
    navigator.clipboard.writeText(data);
  };

  const redirectToSignUp = () => {
    const plan = selectedPlan || selectedAffiliate || selectedDistributor;
    const isTourism = plan ? plan.toLowerCase().includes('travel') : false;
    const params = getSearchParams(
      selectedPlan || selectedAffiliate || selectedDistributor,
      selectedPlan,
      isTourism,
    );
    history.push({
      pathname: signUpRoute,
      search: new URLSearchParams((params as unknown) as Record<string, string>).toString(),
    });
  };

  const isLoading = isDistributorsLoading || isPricingPlansLoading || isAffiliatesLoading;

  if (isLoading) {
    return null;
  }

  const renderSVTestPlans = () => {
    //SV Pay → SV Enterprise → SV Health Pay → SV Health Enterprise
    //TB Pay → TB Enterprise
    const order = [];

    const SVPay = currentDistributorPricingPlans.find(({ name }) => name === 'SV Pay');
    const HealthPay = currentDistributorPricingPlans.find(({ name }) => name === 'Health Pay');
    const TBPay = currentDistributorPricingPlans.find(({ name }) => name === 'TB Pay');

    if (SVPay) order.push(SVPay);
    if (isTravelAndTourism) {
      order.push(enterprisePlans[4]);
      order.push(SVTravelenterPrisePlan);
    } else {
      order.push(enterprisePlans[0]);
      order.push(enterprisePlans[1]);
      // order.push(SVTravelenterPrisePlan);
    }

    if (HealthPay) order.push(HealthPay);

    if (TBPay) order.push(TBPay);
    // order.push(enterprisePlans[2]);

    return order.map((plan: any) => {
      return (
        <PlanTile
          showCopyIcon={true}
          handleCopyToClipboard={() => handleCopyToClipboard(plan.id)}
          key={plan.id}
          name={plan.name}
          monthlyFee={plan.monthlyFee || plan.subscriptionFee}
          setupFee={plan.setupFee}
          promoCode={plan.code}
          selected={selectedPlan === plan.id}
          onClick={() => {
            resetPlansAffiliatesState();
            setSelectedPlan(plan.id);
            trackEvent(PlansEventNames.planTileClicked, { planId: plan.id });
          }}
        />
      );
    });
  };

  return (
    <PageWithHeaderLayout
      header={{
        title: 'Choose A Subscription Plan',
        leftButton: {
          outlined: true,
          title: 'Go Back',
          onClick: () => {
            history.goBack();
            trackEvent(PlansEventNames.backButtonClicked);
          },
        },
        rightButton: {
          title: 'Activate Account Form',
          endIcon: <ChevronRightIcon style={{ fill: 'white' }} />,
          disabled: !canGoToSignUp,
          borderWidth: canGoToSignUp ? '4px' : '0px',
          bdColor: canGoToSignUp ? '#FDB927' : undefined,
          onClick: () => {
            redirectToSignUp();

            trackEvent(PlansEventNames.goToSignUpFormButtonClicked);
          },
        },
      }}
      isFixed={true}
    >
      <Box px="40px" pt="70px" mt="60px" zIndex="1">
        <Box display="flex" gap="16px" mb="30px">
          {categories.map(({ id, name, imageUrl }) => (
            <DistributorTile
              key={id}
              selected={selectedCategory === id}
              name={name}
              logoUrl={imageUrl || DefaultImageUrl}
              onClick={() => {
                if (selectedCategory === id) return; // click on selected
                resetPlansAffiliatesState();
                setSelectedCategory(id);
                trackEvent(PlansEventNames.distributorTileClicked, { distributorId: id });
              }}
            />
          ))}
        </Box>

        <Box display="flex" flexDirection="column" gap="16px">
          {currentCategoryPricingPlans.length > 0 && (
            <TileList
              title={categories.find((item) => item.id === selectedCategory)?.name || ''}
              redirectToSignUp={redirectToSignUp}
              canGoToSignUp={canGoToSignUp}
            >
              {!isTestDistributor &&
                currentCategoryPricingPlans.map(
                  ({ id, name, code, subscriptionFee, setupFee, billingPeriod }) => (
                    <PlanTile
                      showCopyIcon={showCopyIcon}
                      handleCopyToClipboard={() => handleCopyToClipboard(id)}
                      key={id}
                      name={name}
                      monthlyFee={subscriptionFee}
                      setupFee={setupFee}
                      promoCode={code}
                      selected={selectedPlan === id}
                      billingPeriod={billingPeriod}
                      onClick={() => {
                        resetPlansAffiliatesState();
                        setSelectedPlan(id);
                        trackEvent(PlansEventNames.planTileClicked, { planId: id });
                      }}
                    />
                  ),
                )}
              {isSVAdmin && !isTestDistributor && (
                <PlanTile
                  showCopyIcon={true}
                  handleCopyToClipboard={() => handleCopyToClipboard(enterPrisePlan.id)}
                  key={enterPrisePlan.id}
                  name={
                    isTravelAndTourism
                      ? EnterprisePLanStrings.SVTravelEnterpriseName
                      : enterPrisePlan.name
                  }
                  monthlyFee={enterPrisePlan.monthlyFee}
                  setupFee={enterPrisePlan.setupFee}
                  promoCode={enterPrisePlan.code}
                  selected={selectedPlan === enterPrisePlan.id}
                  onClick={() => {
                    resetPlansAffiliatesState();
                    setSelectedPlan(enterPrisePlan.id);
                    trackEvent(PlansEventNames.enterprisePlanTileClicked, {
                      value: enterPrisePlan.code,
                    });
                  }}
                />
              )}
              {isSVAdmin && isTestDistributor && renderSVTestPlans()}
            </TileList>
          )}

          {currentDistributorAffiliates.length > 0 && (
            <TileList title="Affiliates">
              {currentDistributorAffiliates.map(({ id, name, code, plan }) => (
                <PlanTile
                  showCopyIcon={showCopyIcon}
                  handleCopyToClipboard={() => handleCopyToClipboard(id)}
                  key={id}
                  name={name}
                  monthlyFee={plan?.subscriptionFee}
                  setupFee={plan?.setupFee}
                  promoCode={code}
                  selected={selectedAffiliate === id}
                  onClick={() => {
                    resetPlansAffiliatesState();
                    setSelectedAffiliate(id);
                    trackEvent(PlansEventNames.planTileClicked, { planId: id });
                  }}
                />
              ))}
            </TileList>
          )}
        </Box>
      </Box>
    </PageWithHeaderLayout>
  );
};
