export const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const NEW_URL = process.env.REACT_APP_NEW_API_URL;

export const AuthEndpoints = {
  NewLogin: `/api/v1/auth/customer`,
  Login: `/api/customer/auth/login`,
  RefreshToken: `/api/v1/auth/manager/refresh`,
  GetMe: `/api/v1/manager-accounts/:id`,
  PatchProfile: `/api/customer/auth/me`,
  PatchMe: `/api/v1/manager-accounts/:id`,
  ForgotPassword: '/api/v1/manager-accounts/forgot-password',
  ResetPassword: '/api/v1/manager-accounts/reset-password',
  ValidateToken: '/api/v1/manager-accounts/validate-pwr-token',
  PartnerLogin: '/api/v1/auth/manager/partner-token',
};

export const VenueEndpoints = {
  Venue: `/api/customer/accounts`,
  GetVenueProperties: `/api/v1/accounts/:id/properties`,
  CreateOrUpdateVenueAttributes: `/api/customer/venue/attributes`,
  PatchVenue: `/api/v1/accounts/:id`,
  GetAccountById: `/api/v1/accounts/:id`,
  GetVenueByIdOrSubdomain: `/api/v1/accounts/:id/stats`,
  GetServiceAgreement: '/api/v1/accounts/:id/service-agreement',
  UploadServiceAgreement: '/api/v1/accounts/:id/upload-service-agreement',
  PatchVenueProperties: `/api/v1/accounts/:id/properties`,
  GetVenuesByAccountManagerId: '/api/v1/manager-accounts/:id/accounts',
  CreateVenue: '/api/v1/accounts',
  GetAnalyticsMarket: '/api/v1/analytics/market/',
  RegisterVenue: '/api/v1/accounts/register',
  RequestEnterprice: '/api/v1/request-enterprise-demo',
  Deactivate: '/api/v1/accounts/:id/deactivate',
  Reactivate: '/api/v1/accounts/:id/reactivate',
  GetAccountsWithSearchFilter: '/api/v1/accounts/search?withStats=true',
  GetDMOList: '/api/v1/dmo-accounts',
  FinalizeAccount: 'api/v1/accounts/:id/setup/finalize',
  CreateAccountCreationIntent: '/api/v1/account-creation-intent',
  GetAccountCreationIntent: '/api/v1/account-creation-intent/:id',
};

export const ServiceAgreementEndpoints = {
  generateServiceAgreement: `/api/v1/service-agreements/generate`,
};

export const UsersEndpoints = {
  GetUsers: `/api/v1/users`,
  GetUser: `/api/v1/users/:id`,
  GetCampaignUser: `/api/v1/incentive-campaigns/:campaignId/users/:userId`,
  GetUserMetrics: `/api/v1/users/:id/metrics`,
  GetUsersStats: `/api/v1/users/stats`,
  GetUserRewards: `/api/customer/users/:id/rewards`,
  GetUserPerformanceRecords: `/api/customer/user-performance-records`,
  AddPointsManually: `/api/customer/user-performance-records`,
  GetUserRefereeRedeemed: `/api/customer/users/:id/referee-redeemed`,
  VerifyPerformanceRecords: `/api/customer/user-performance-records/:id/verify`,
  UnverifyPerformanceRecords: `/api/customer/user-performance-records/:id/unverify`,
  UpdateUserReward: `/api/customer/users/:id/rewards/:userRewardId/:action`,
  PostUser: `/api/v1/users/invite`,
  PostUsersCSVFile: `/api/v1/users/import`,
  updateUserById: `/api/v1/users/:id`,
  PostAllUsers: `/api/v1/users/send-invites`,
  InviteOneUser: `/api/v1/users/:id/invite`,
  getUsersBySearchFilter: '/api/v1/users/search',
  PostBatchUsersPoints: '/api/v1/users/batch-add-bonus-points',
  CreateUser: `/api/v1/users`,
  GetLatestActiveCampaign: `/api/v1/users/:id/incentive-campaigns/latest-active`,
  GetUserCounts: `api/v1/users/counts`,
};

export const MediaEndpoints = {
  UploadFile: `/api/customer/media/upload`,
  UploadFileFromUrl: `/api/customer/media/upload-from-url`,
  CreateUploadSignedUrl: `/api/v1/accounts/:id/assets/create-upload-signed-url`,
  CreateUploadUrl: '/api/v1/storage/create-upload-signed-url',
  CreateVideoUploadUrl: `/api/v1/videos/create-upload-signed-url`,
  CreateAvatarUploadUrl: `/api/v1/users/create-avatar-upload-signed-url`,
  CreateThumbnailUploadUrl: `/api/v1/videos/create-thumbnail-signed-url`,
};

export const CampaignsEndpoints = {
  GetIncentiveCampaigns: '/api/v1/incentive-campaigns',
  GetActiveIncentiveCampaign: 'api/v1/accounts/:id/active-incentive-campaign',
  GetUserActiveCampaign: '/api/v1/users/:id/active-campaign',
  PostIncentiveCampaigns: `/api/v1/incentive-campaigns`,
  PostEndIncentiveCampaign: `/api/v1/incentive-campaigns/:id/end`,
  PatchIncentiveCampaign: `/api/v1/incentive-campaigns/:id`,
  PostSalesCalculation: `/api/v1/incentive-campaigns/calculate/sales`,
  PostFundraisingCalculation: `/api/v1/incentive-campaigns/calculate/fundraising`,
  PostETSCalculation: `/api/v1/incentive-campaigns/calculate/ets`,
  PostMarketingCalculation: `/api/v1/incentive-campaigns/calculate/marketing`,
  GetCampaignById: `/api/customer/amplfi-campaigns/:id`,
  GetPrimaryCampaign: `/api/v1/accounts/:id/preferred-campaign`,
  CreateCampaigns: `/api/customer/amplfi-campaigns`,
  PatchCampaigns: `/api/customer/amplfi-campaigns/:id`,
  DeleteCampaignById: `/api/customer/amplfi-campaigns/:id`,
  UpdateCampaignFilter: `/api/customer/amplfi-campaigns/:id/update-video-filter`,
  GenerateRewardCard: `/api/v1/incentive-campaigns/generate-image`,
  GenerateRewardCardUrl: `/api/v1/incentive-campaigns/generate-image-url`,
  AddUsersToCampaign: `/api/v1/incentive-campaigns/:id/add-users`,
  GetCampaignUsers: `/api/v1/incentive-campaigns/{id}/users`,
  GetRewardCampaignById: `/api/v1/incentive-campaigns/{id}`,
  GetRewardCampaignByIdWithStats: `/api/v1/incentive-campaigns/:id?withStats=true`,
  GetCampaignCountData: `api/v1/incentive-campaigns/:id/users-counts`,
  RemoveUsersFromCampaign: `/api/v1/incentive-campaigns/:id/remove-users`,
  GetCampaignVideos: `/api/v1/incentive-campaigns/{id}/videos`,
  GetUserIncentiveCampaigns: '/api/v1/users/:id/incentive-campaigns',
  GetCampaignInvitationBatches: '/api/v1/incentive-campaigns/:id/invitation-batches',
  CreateInvitation: '/api/v1/incentive-campaigns/:id/invitations',
  SendInvitations: '/api/v1/incentive-campaigns/:id/invitations/send',
  ReSendCampaignInvitations: '/api/v1/incentive-campaigns/:id/invitations/:invitationId/send',
  DeleteInvitations: '/api/v1/incentive-campaigns/:id/invitations/delete-unsent',
  SendInvitationsBatch: '/api/v1/incentive-campaigns/:id/invitations/batch',
  GetUnsentInvitations: '/api/v1/incentive-campaigns/:id/invitations/unsent',
  GetQueuedInvitations: '/api/v1/incentive-campaigns/:id/invitations/queued',
  RemoveFromQueue: 'api/v1/incentive-campaigns/:id/invitations/remove-from-queue',
  GetActiveInvitations: '/api/v1/incentive-campaigns/:id/invitations/active',
  GetUserInvitedCampaigns: '/api/v1/campaign-invitations',
  PostSelfInvite: `/api/v1/incentive-campaigns/:id/invitations/self-invite`,
  Visitors: `/api/v1/incentive-campaigns/:id/stats/visitors`,
  GetInviteCounts: `api/v1/incentive-campaigns/:id/stats/invites`,
  GetDeliveredInvitations: `api/v1/incentive-campaigns/:id/invitations/delivered`,
  GetUndeliveredInvitations: `api/v1/incentive-campaigns/:id/invitations/undelivered`,
  GetLastSendBatchStats: `api/v1/incentive-campaigns/:id/invitations/last-send-batch-stats`,
  GetInvitationsQueueStats: `api/v1/incentive-campaigns/:id/invitations/queue-stats`,
  ValidatePhoneNumbers: '/api/v1/incentive-campaigns/:id/invitations/check-phone-numbers',
};

export const VideosEndpoints = {
  GetVideos: `/api/v1/videos`,
  GetVideoById: `/api/v1/videos/:id`,
  DeleteVideoById: `/api/v1/videos/:id`,
  UpdateVideoById: `/api/v1/videos/:id`,
  GetVideoMetrics: `/api/v1/videos/:id/metrics`,
  GetVideosBySearchFilter: '/api/v1/videos/search',
  GetVideosShareSummary: `/api/v1/videos/:id/shares-summary`,
  CreateVideo: `/api/v1/videos`,
  GetSvMetrics: `/api/v1/videos/:id/sv-metrics`,
};

export const TopGolfVideosEndpoints = {
  GetVideos: `/api/v1/topgolf/videos`,
  GetVideoById: `/api/v1/topgolf/videos/:id`,
  UpdateVideoById: `/api/v1/topgolf/videos/:id`,
  GetVideosShareSummary: `/api/v1/topgolf/videos/:id/shares-summary`,
  GetVideoMetrics: `/api/v1/video/:id/metrics`,
  getVideosBySearchFilter: '/api/v1/topgolf/videos/search',
};

export const AnalyticsEndpoints = {
  GetGeneralKPIs: `/api/v1/analytics/kpi`,
  GetKPI: `/api/v1/analytics/kpi/:id`,
  GetShareKPI: `/api/v1/analytics/kpi/shares`,
  GetTGSGeneralKPIs: `/api/v1/analytics/kpi/topgolf-summary`,
  getShareSummary: `/api/v1/analytics/kpi/shares-summary`,
  GetSuperAdminAnalytics: '/api/v1/analytics/global-stats',
};

export const FiltersEndpoints = {
  GetVideoFilters: `/api/v1/video-filters`,
  GenerateFilters: '/api/v1/video-filters/generate-filter-images',
  DeactivateFilter: '/api/v1/video-filters/:id/deactivate',
  ActivateFilter: '/api/v1/video-filters/:id/activate',
  CreateNewFilter: '/api/v1/video-filters',
};

export const RewardsEndpoints = {
  GetRewardsList: `/api/v1/rewards`,
  PutRewardById: `/api/v1/rewards/:id`,
  PostReward: `/api/v1/rewards`,
  PostRewardCalculation: `/api/v1/rewards/calculate-ets`,
  GetActivities: `/api/v1/activities`,
  GetActiveReward: `/api/v1/accounts/:id/active-reward`,
};

export const GbpEndpoints = {
  GetAuthorizeUrl: `/api/v1/gbp/setup/authorize`,
  Oauth2: `/api/v1/gbp/setup/oauth2callback`,
  Accounts: `/api/v1/gbp/setup/accounts`,
  Locations: `/api/v1/gbp/setup/accounts/:id/locations`,
  ConfirmLocation: `/api/v1/gbp/confirm-location`,
  ConnectedAccount: `/api/v1/gbp/connected-account`,
  DisconnectAccount: `/api/v1/gbp/disconnect`,
};

export const UsersRewards = {
  GetActivities: '/api/v1/users/:id/rewards/activities',
  ApproveReward: '/api/v1/user-rewards/approve',
  ApproveRewardBatch: '/api/v1/user-rewards/approve-batch',
  AddBonusPoints: '/api/v1/users/:id/add-bonus-points',
};
export const VenueCreationEndpoints = {
  GetEtsCompaignData: '/api/v1/integrations/ets/campaign/:id',
};
export const VenueDomainsEndpoints = {
  CheckAvailability: '/api/v1/venue-domains/check-availability',
};

export const ETSIntegrationEndpoints = {
  CreateVenue: '/api/v1/integrations/ets/create-venue',
};

export const AtlassianEndpoints = {
  Content: '/wiki/rest/api/content',
};

export const VenueManagerEndpoints = {
  GetVenueManagersList: '/api/v1/accounts/:id/managers',
  CreateVenueManager: '/api/v1/accounts/:id/managers',
  SendInvite: '/api/v1/accounts/:id/managers/:managerId/send-invitation',
  ActivateManager: '/api/v1/accounts/:id/managers/:managerId/activate',
  DeactivateManager: '/api/v1/accounts/:id/managers/:managerId/deactivate',
  DeleteManager: '/api/v1/accounts/:id/managers/:managerId',
  EnableVideoAlerts: '/api/v1/accounts/{accountId}/managers/{id}/enable-video-alerts',
  DisableVideoAlerts: '/api/v1/accounts/{accountId}/managers/{id}/disable-video-alerts',
};

export const searchFilterEndpoints = {
  GetSearchFilterById: '/api/v1/search-filters/:id',
  GetSearchFilters: '/api/v1/search-filters',
  PostSearchFilters: '/api/v1/search-filters',
  DeleteSearchFilters: '/api/v1/search-filters/:id',
};

export const BillingEndpoints = {
  GetBillingConfig: '/api/v1/billing/config',
  GetPrisingPlan: 'api/v1/accounts/:id/pricing-plan',
  CreateCheckoutSession: '/api/v1/billing/create-checkout-session',
  CreateCustomerPortalSession: '/api/v1/billing/create-customer-portal-session',
  GetBillingSubscriptionDetailsSimplified: '/api/v1/billing/subscription-details-simplified',
};

export const SocialVerseEndpoints = {
  GetSocialVerseById: '/api/v1/socialverses/',
  GetAllSocialVerses: '/api/v1/socialverses',
  PostCreateSocialVerse: '/api/v1/socialverses',
  PutUpdateSocialVerse: '/api/v1/socialverses/',
  GetVideosBySocialVerse: '/api/v1/socialverses/:id/videos',
  AddVideoToSocialVerse: '/api/v1/socialverses/:id/videos',
  RemoveVideoFromSocialVerse: '/api/v1/socialverses/:id/videos/:videoId',
  MoveVideoPositionInSocialVerse: '/api/v1/socialverses/:id/videos/:videoId',
  BatchVideosToSocialVerse: '/api/v1/socialverses/:id/videos/batch',
  GetSvDeployments: '/api/v1/socialverses/:id/deployments',
};
export const KeywordsEndpoints = {
  GetKeywordsByVenueId: '/api/v1/keywords',
  PostKeywordsByVenueId: '/api/v1/keywords',
  DeleteAllKeywordsByVenueId: '/api/v1/keywords',
  DeleteKeywordByVenueId: '/api/v1/keywords/:id',
};

export const SMSInviteEndpoints = {
  GetConfig: '/api/v1/messaging/config',
  GetVariables: '/api/v1/messaging/variables',
  PostSendTestMessage: '/api/v1/messaging/send-test-message',
  PostUpdateConfig: '/api/v1/messaging/config',
};

export const DataSyncEndpoints = {
  GetTransactionBatches: '/api/v1/messaging/transaction-batches',
  GetTransactionBatchById: '/api/v1/messaging/transaction-batches/:id',
  GetTransactionBatchRecordsById: '/api/v1/messaging/transaction-batches/:id/records',
  GetTransactionRecordsByCampaignId: '/api/v1/incentive-campaigns/:id/invitations',
  PostTransactionBatch: '/api/v1/messaging/transaction-batches',
  UpdateTransactionBatch: '/api/v1/messaging/transaction-batches/:id',
  PutTransactionBatch: '/api/v1/messaging/transaction-batches/:id/approve',
  PostTransactionsImportCsv: '/api/v1/messaging/transactions/import-csv',
  PostImportTransactions: '/api/v1/messaging/transactions/import',
};

export const RewardFulfillmentEndpoints = {
  GetRewardFulfillmentConfig: '/api/v1/reward-fulfillment-config',
  PostRewardFulfillmentConfig: '/api/v1/reward-fulfillment-config',
  PostRewardFulfillmentTestWebhook: '/api/v1/reward-fulfillment-config/test-webhook',
  PostRewardFulfillmentTestSMS: '/api/v1/reward-fulfillment-config/test-sms',
};

export const AffiliateEndpoints = {
  GetAffiliates: '/api/v1/affiliates',
  GetAffiliateByIdOrCode: '/api/v1/affiliates/:idOrCode',
  PostAffiliates: '/api/v1/affiliates',
  PutAffiliates: '/api/v1/affiliates/:id',
};

export const DistributorEndpoints = {
  GetDistributors: '/api/v1/distributors',
  GetDistributorByIdOrCode: '/api/v1/distributors/:idOrCode',
  PostDistributors: '/api/v1/distributors',
  PutDistributors: '/api/v1/distributors/:id',
};

export const PricingPlanEndpoints = {
  GetPricingPlans: '/api/v1/pricing-plans',
  GetPricingPlanByIdOrCode: '/api/v1/pricing-plans/:idOrCode',
  PostPricingPlans: '/api/v1/pricing-plans',
  PutPricingPlans: '/api/v1/pricing-plans/:id',
  PricingPlansCategories: '/api/v1/pricing-plan-categories',
};

export const ReferralCodeEndpoints = {
  GetPlanByCode: '/api/v1/referral-codes/:code',
};

export const SmsMessageEndpoints = {
  GetSmsMessages: '/api/v1/sms-message',
  GetMessagesTotalCounts: '/api/v1/sms-message/total-counts-by-venue',
  GetSmsMessagesCounts: '/api/v1/sms-message/count-by-account',
};

export const PeekEndpoints = {
  GetPeekAccount: '/api/v1/peek/connected-account',
  ConnectAccount: '/api/v1/peek/connect',
  DisconnectAccount: '/api/v1/peek/disconnect',
};

export const getApiUrlForId = (apiUrl: string, id: string): string => {
  return apiUrl.replace(/:id/, id);
};
