import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ClientTypes } from '../../../api/models/common';
import { LogoPresentation, VenueProperties, WebAppSignUpConfig } from '../../../api/models/venue';
import {
  RegisterVenueRequest,
  SVADMINRegisterVenueRequest,
} from '../../../api/models/venueCreation';
import {
  SV_DEFAULT_INCENTIVE_IMAGE,
  TRUBLU_DEFAULT_INCENTIVE_IMAGE,
  TRUBLU_DEFAULT_WEBAPP_CARD,
  UserRoles,
} from '../../../common/constants/constants';
import {
  DEFAULT_BRAND_COLOR,
  DEFAULT_REWARD_SUBTITLE,
  DEFAULT_REWARD_SUBTITLE_TRUBLU,
  DEFAULT_REWARD_TITLE,
  DEFAULT_REWARD_TITLE_TRUBLU,
  DEFAULT_STORY_CTA_BUTTON,
} from '../../../common/constants/defaults';
// import { useFTE } from '../../hooks/useFTE';
import { useAppDispatch, useTypedSelector } from '../../../store';
// import { authRequest, getUserAccounts } from '../../store/slices/meSlice';
import { SVAdminRegisterVenue } from '../../../store/slices/venueCreationSlice';
// import { setSelectedVenue } from '../../store/slices/venueSlice';
// import { CongratsModal } from './CongratsModal';
import { useStyles } from '../signupSuccess.helper';
import { SocialVenuLogo } from '../../../common/assets/newDesign/Sidebar/SocialVenuLogo';
import { Box, Button } from '@mui/material';
import { isMobile } from 'react-device-detect';

export const EnterpriseFlow = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isLoading, created, error, payload } = useTypedSelector(
    (state) => state.venueCreation.createdVenue,
  );
  const formData = localStorage.getItem('signupForm');
  const formValues = formData ? JSON.parse(formData) : {};
  const industry = localStorage.getItem('industry');
  const { role } = useTypedSelector((state) => state.me);
  const isSVAdmin = role === UserRoles.admin;
  const [queryStrings, setQueryStrings] = useState('');

  const clientType = useRef((formValues?.clientType as ClientTypes) || ClientTypes.MARKETING)
    .current;
  const isTruBlu = clientType === ClientTypes.TRUBLU;
  const isHealthCare = isTruBlu || clientType === ClientTypes.SV_HEALTH;

  useEffect(() => {
    if (queryStrings) {
      localStorage.setItem('signupForm', '');
    }
  }, [queryStrings]);

  const logoUrl = '';

  const ctaBtnText = `Visit ${
    formValues?.practiceName ? formValues.practiceName : formValues?.companyName
  }`;

  const baseRegisterVenueRequestBody: Omit<RegisterVenueRequest, 'stripCheckoutSessionId'> = {
    businessAddress: null,
    name: (formValues?.practiceName as string) || (formValues?.companyName as string) || '',
    websiteUrl: (formValues?.companyWebsite as string) || '',
    referralCode: (formValues?.planCode as string) || 'BASIC',
    industry: (formValues?.industry as string) || industry || null,
    soldBy: (formValues?.soldBy as string) || undefined,
    clientType: (formValues?.clientType as ClientTypes) || ClientTypes.MARKETING,
    managerAccount: {
      email: (formValues?.email as string) || '',
      firstName: (formValues?.firstName as string) || '',
      lastName: (formValues?.lastName as string) || '',
      password: (formValues?.password as string) || '',
      phoneNumber: (formValues?.phone as string) || '',
    },
    logoUrl: logoUrl,
    properties: {
      onboarding_attributes: {
        email_system: formValues?.emailSystem,
        sms_system: formValues?.smsSystem,
        referrer: formValues?.aboutUs,
      },
      'webapp.config': {
        'logo-url': logoUrl,
        'primary-color': DEFAULT_BRAND_COLOR,
        'filter-logo-enabled': true,
      },
      'webapp.cards-welcome': {
        mobile: {
          original: isTruBlu ? TRUBLU_DEFAULT_WEBAPP_CARD : '',
          cropped: isTruBlu ? TRUBLU_DEFAULT_WEBAPP_CARD : '',
        },
        tablet: {
          original: isTruBlu ? TRUBLU_DEFAULT_WEBAPP_CARD : '',
          cropped: isTruBlu ? TRUBLU_DEFAULT_WEBAPP_CARD : '',
        },
        desktop: {
          original: isTruBlu ? TRUBLU_DEFAULT_WEBAPP_CARD : '',
          cropped: isTruBlu ? TRUBLU_DEFAULT_WEBAPP_CARD : '',
        },
        title: isHealthCare ? DEFAULT_REWARD_TITLE_TRUBLU : '',
        subtitle: isHealthCare ? DEFAULT_REWARD_SUBTITLE_TRUBLU : '',
      },
      'webapp.incentive.config': {
        title: isHealthCare ? DEFAULT_REWARD_TITLE_TRUBLU : DEFAULT_REWARD_TITLE,
        action: isHealthCare ? DEFAULT_REWARD_SUBTITLE_TRUBLU : DEFAULT_REWARD_SUBTITLE,
        imageUrl: isTruBlu ? TRUBLU_DEFAULT_INCENTIVE_IMAGE : SV_DEFAULT_INCENTIVE_IMAGE,
        points: 100,
        activities: [
          { activityType: 'CREATE_VIDEO', points: 25, status: true },
          { activityType: 'SHARE', points: 10, status: true },
          { activityType: 'CALL_TO_ACTION', points: 50, status: true },
          { activityType: 'BONUS_POINTS', points: 100, status: true },
        ],
      },
      'webapp.default-story-details': {
        ctaBtnText: ctaBtnText.length > 30 ? DEFAULT_STORY_CTA_BUTTON : ctaBtnText,
        ctaBtnLinkUrl: (formValues?.companyWebsite as string) || '',
      },
      'webapp.signUp.config': {
        phoneRequired: true,
        nameRequired: true,
        emailRequired: !isTruBlu,
        logoPresentation: LogoPresentation.Default,
        showCompanyName: true,
      } as WebAppSignUpConfig,
      'dashboard.config': {
        dashboardLogo: logoUrl,
      },
      'dashboard.QRCode.config': {
        logo: logoUrl,
        brandColor: DEFAULT_BRAND_COLOR,
      },
      legal_name: (formValues?.companyName as string) || '',
      'company-address': {
        address: formValues?.address,
        city: formValues?.city,
        state: formValues?.state,
        zip: formValues?.zip,
      } as VenueProperties['company-address'],
      'quickstart.config': {
        currentRoute: '/quickstart/welcome',
        isQuickStartInProgress: true,
      },
    },
  };

  const svAdminregisterVenueRequestBody: SVADMINRegisterVenueRequest = {
    ...baseRegisterVenueRequestBody,
    quickFormRegistration: true,
  };

  //https://storage.googleapis.com/sv-dev-assets/14eef41f-8001-4c43-9a02-838dfecdf837/7b92f74f-2ff5-47ab-844a-838ff8550b12.jpg

  useEffect(() => {
    if (isSVAdmin && !isLoading && !created) {
      dispatch(SVAdminRegisterVenue(svAdminregisterVenueRequestBody)).then((response) => {
        if (response && response.payload) {
          const firstName = encodeURIComponent((formValues?.firstName as string) || '');
          const lastName = encodeURIComponent((formValues?.lastName as string) || '');
          const customerName = encodeURIComponent(firstName + ' ' + lastName);
          const accountName = encodeURIComponent(
            (formValues?.practiceName as string) || (formValues?.companyName as string) || '',
          );
          const email = encodeURIComponent((formValues?.email as string) || '');
          const phoneNumber = encodeURIComponent((formValues?.phone as string) || '');
          const token = encodeURIComponent(response.payload.token);
          const accountId = encodeURIComponent(response.payload.account.id);
          const isTest = response.payload.account?.isTest ? true : false;
          const queryStrings = `token=${token}&accountId=${accountId}&customerName=${customerName}&accountName=${accountName}&email=${email}&phoneNumber=${phoneNumber}${
            isTest ? '&t=1' : ''
          }`;
          setQueryStrings(queryStrings);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSVAdmin, dispatch]);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);
  ///complete-profile?token=EV0Aja1wmo3IuEJ3dtwVkg1I2hw7N1xd&customerName=Yassine+AB&accountName=Account+Signup+update&email=yassine%2Baccountsignupupdate%40socialvenu.com&phoneNumber=%2B19783491570
  const handleOnCompleteSetupClick = () => {
    if (payload && payload.token) {
      history.push(`/complete-profile?${queryStrings}`);
    }
  };
  //   if (!isSVAdmin && !checkoutSessionId) {
  //     return null;
  //   }

  /*  const handleContactUsClick = () => {
    const a = document.createElement('a');
    a.href = `mailto:${ContactUsString.SupportEmail1}?cc=${ContactUsString.SupportEmail2}&subject=${ContactUsString.ErrorSignUp}`;
    a.click();
  };*/

  return (
    <>
      <div className={classes.QuicksignUpWrapper}>
        <Box
          style={
            isMobile
              ? {
                  borderRadius: '15px',
                  background: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '112px 40px 82px 40px',
                  margin: '40px',
                  width: '408px',
                  marginTop: '-500px',
                  scale: '1.4',
                }
              : {
                  borderRadius: '15px',
                  background: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '112px 33px 49px 35px',
                  margin: '40px',
                  width: '598px',
                  height: '408px',
                  marginTop: '-300px',
                }
          }
        >
          <div style={{ marginBottom: '20px', marginTop: '-100px' }}>
            <SocialVenuLogo />
          </div>
          <p className={classes.boldparagraph}>Welcome to SocialVenu!</p>
          <p
            className={[
              classes.paragraph,
              isLoading ? classes.paragraphLoading : classes.paragraphLoaded,
            ].join(' ')}
          >
            Your account is being created...
          </p>
          <p className={classes.boldparagraph}>
            Click below to complete account setup, create a password and begin customizing to your
            business.
          </p>
          <div>
            <Button
              color="primary"
              variant="contained"
              className={classes.completePorfileButton}
              disabled={isLoading || error}
              onClick={handleOnCompleteSetupClick}
            >
              Complete Account Setup
            </Button>
          </div>
        </Box>
      </div>
    </>
  );
};
