import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useFormik } from 'formik';

import { LoginPageStrings, SettingsPageStrings, SingUpPage } from '../../../common/localization/en';
import {
  castURLToHttps,
  getValidationSchemaCompleteProfileForm,
  getSVAdminValidationSchemaCompleteProfileForm,
} from './SignUpForm.helper';
import { useSignUpFormStyles } from './SignUpForm.style';
import { useAppDispatch, useTypedSelector } from '../../../store';
// import { convertStripePriceToDollars } from '../../../services/utilities';
import { states, UserRoles, VenueMappingRoles } from '../../../common/constants/constants';
import {
  VALID_PHONE_VALIDATION_STATE,
  PhoneValidationState,
} from '../../../common/components/PhoneField/PhoneField';
import { CustomTextField } from '../../../common/components/customTextField/CustomTextField';
import { CustomSelectWithTextField } from '../../../common/components/customSelect/CustomSelectWithTextField';
import { CustomButton } from '../../../common/components/customButton/customButton';
// import { Signature } from '../../../common/components/Signature/Signature';
import { useQuery } from '../../../hooks/common';
import { PricingPlanPreview } from './components/PricingPlanPreview';

import { URLTextField } from '../../../common/components/TextField/URLTextField';
import { PlanBackgroundTopIcon } from '../../../common/assets/newDesign/PlanBackgroundTopIcon';
import { PlanBackgroundSideIcon } from '../../../common/assets/newDesign/PlanBackgroundSideIcon';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { SignUpEventNames } from '../../../common/constants/events/signUpEventNames';
import { useHandleChangeWithTracking } from '../../../services/hooks/useHandleChangeWithTracking';
import { authRequest, getUserAccounts, ResetPassword } from '../../../store/slices/meSlice';
import { useToasts } from 'react-toast-notifications';
import { SVenterPrisePlan } from '../../plans/PlansPage';
import {
  getProperties,
  getVenue,
  getAccountById,
  setSelectedVenue,
  getDMOList,
  finalizeAccount,
} from '../../../store/slices/venueSlice';
import { getVenueList } from '../../../store/slices/organizationVenueSlice';
import { useHistory } from 'react-router-dom';
import { UnprotectedRoutes, WebsiteRoutes } from '../../../common/constants/routes';
import { isMobile } from 'react-device-detect';
import { useCreateDefaultCampaign } from '../useCreateDefaultCampaign';
import PlaceAutoComplete from '../../../common/components/PlaceAutoComplete/PlaceAutoComplete';
import { getVenueManagerList } from '../../../store/slices/venueManagerSlice';
import { VenueManagerApiModel } from '../../../api/models/venueManagers';
import { SocialVenuLogo } from '../../../common/assets/newDesign/Sidebar/SocialVenuLogo';
import { getPricingPlanByIdOrCode } from '../../../store/slices/pricingPlansSlice';
import { TOURISM_INDUSTRY } from '../../../api/models/pricingPlans';
import { formatPhoneNumber } from '../../../services/utilities';

interface Props {}

export const NEW_DMO_ID = 'new_dmo';

export const CompleteProfile: FC<Props> = () => {
  const query = useQuery();
  const token = query.get('token');

  const accountId = query.get('accountId');

  const { trackEvent } = useTrackEvent();
  // const currentPlan = planId && plans ? plans[planId as PricingPlansTypes] : null;
  const classes = useSignUpFormStyles();
  // const planName = currentPlan?.name || '';
  // const planPrice = convertStripePriceToDollars(currentPlan?.priceAmount || 0);
  const dispatch = useAppDispatch();
  // const currentPlanId = currentPlan ? backendPricingPlans[planId as PricingPlansTypes] : '';
  const { auth, role } = useTypedSelector((state) => state.me);
  const { setupCompleted, isLoading, id, name, planId, industry } = useTypedSelector(
    (state) => state.venue.venue,
  );
  // const { activePricingPlan } = useTypedSelector((state) => state.pricingPlans);
  const { items: managers } = useTypedSelector((state) => state.VenueManagerSlice);
  const { items } = useTypedSelector((state) => state.OrganizationVenueSlice);
  const { addToast } = useToasts();
  const [transformedUrl, setTransformedUrl] = useState('');
  const [businessAdress, setBusinessAdress] = useState('');
  const [describe, setDescribe] = useState('');
  const [DMO, setDMO] = useState('');
  const [address, setAddress] = useState({ address: '', city: '', state: '', zip: '' });
  const [isEnabled, setIsEnabled] = useState(true);
  const history = useHistory();
  const { createDefaultCampaign, createFirstCampaign } = useCreateDefaultCampaign();
  const isSVAdmin = role === UserRoles.admin;
  const [owner, setOwner] = useState<VenueManagerApiModel>();
  const isTest = query.get('t') ? true : false;
  const accountName = query.get('accountName') || name || '';
  const email = query.get('email') || owner?.email || '';
  const phone = formatPhoneNumber(query.get('phoneNumber') || owner?.phoneNumber || '', {
    showPlusOne: false,
  });
  const customerName =
    query.get('customerName') || (owner ? owner.firstName + ' ' + owner.lastName : '');

  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const [isActiveCampaign, setIsActiveCampaign] = useState(false);
  const timeoutRef = useRef<number | null>(null);
  const { isLoading: isDMOListLoading, items: DMOItems } = useTypedSelector(
    (state) => state.venue.DMOList,
  );

  const DMOOptions = DMOItems.length
    ? [
        ...DMOItems.map((item) => {
          return { value: item.name, content: item.name, id: item.id };
        }),
        { value: 'I am a DMO', content: 'I am a DMO', id: NEW_DMO_ID },
        { value: 'No', content: 'No', id: '' },
      ]
    : [
        { value: 'I am a DMO', content: 'I am a DMO', id: NEW_DMO_ID },
        { value: 'No', content: 'No', id: '' },
      ];
  const DescribeBusinessOptions = [
    { value: 'Attractions', content: 'Attractions', id: '' },
    { value: 'Restaurant', content: 'Restaurant', id: '' },
    { value: 'Tour Operator', content: 'Tour Operator', id: '' },
    { value: 'Hotel', content: 'Hotel', id: '' },
    { value: 'Other', content: 'Other', id: '' },
  ];

  useEffect(() => {
    if (planId) {
      dispatch(getPricingPlanByIdOrCode({ idOrCode: planId }));
    }
  }, [planId, dispatch]);

  useEffect(() => {
    if (!isDMOListLoading && id) dispatch(getDMOList({ isTest }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  useEffect(() => {
    if (!window.visualViewport) return;

    const handleResize = () => {
      if (window?.visualViewport.height >= window.innerHeight) {
        window.scrollTo(0, 0);
      }
    };

    window.visualViewport.addEventListener('resize', handleResize);

    return () => {
      window?.visualViewport.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (!isKeyboardVisible) {
      timeoutRef.current = setTimeout(() => {
        window.scrollTo(0, 0); // Force a layout reset
      }, 100);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isKeyboardVisible]);

  useEffect(() => {
    const handleFocus = () => setKeyboardVisible(true); // Keyboard appears
    const handleBlur = () => setKeyboardVisible(false); // Keyboard disappears

    window.addEventListener('focusin', handleFocus); // Triggered when input is focused
    window.addEventListener('focusout', handleBlur); // Triggered when input loses focus

    return () => {
      window.removeEventListener('focusin', handleFocus);
      window.removeEventListener('focusout', handleBlur);
    };
  }, []);

  useEffect(() => {
    const root = document.getElementById('root');
    if (root && isMobile) {
      root.setAttribute('style', 'zoom:1');
    }

    return () => {
      if (isMobile && root) {
        root.removeAttribute('style');
      }
    };
  }, []);

  useEffect(() => {
    if (managers && managers.length) {
      const newowner = managers.filter((item) => item.role !== VenueMappingRoles.owner)[0];
      setOwner(newowner);
    }
  }, [managers, setOwner]);

  useEffect(() => {
    if (isSVAdmin && (accountId || id)) {
      dispatch(getVenueManagerList({ accountId: accountId || id }));
    }
  }, [isSVAdmin, accountId, id, dispatch]);
  useEffect(() => {
    if (accountId) {
      dispatch(getAccountById({ id: accountId }));
    }
  }, [accountId, dispatch]);

  useEffect(() => {
    if (setupCompleted && !isLoading && id && !isSVAdmin) {
      const emailQs = encodeURIComponent(email || '');
      const nameQs = encodeURIComponent(customerName || '');
      const tokenQs = encodeURIComponent(token || '');
      const path = emailQs
        ? `/reset-password?token=${tokenQs}&name=${nameQs}&email=${emailQs}`
        : `/reset-password?token=${tokenQs}`;
      history.push(path);
    }
  }, [setupCompleted, isLoading, isSVAdmin, customerName, email, token, history, id]);

  useEffect(() => {
    if (auth && !isSVAdmin) {
      dispatch(setSelectedVenue({ id: null }));
      dispatch(getUserAccounts({}));
      dispatch(getVenueList({}));
      // history.push(WebsiteRoutes.RewardCampaigns);
    }
  }, [auth, dispatch, isSVAdmin]);
  useEffect(() => {
    const CompleteProfileAction = async () => {
      const [account] = items.filter((item) => item.name === accountName);
      if (account && account.id) {
        const isDMOUseCase = account.industry === 'TOURISM';
        const isNewDMO = DMO === NEW_DMO_ID;
        const options = {
          id: account.id,
          websiteUrl: transformedUrl,
          businessAddress: businessAdress,
          dmo_member:
            isDMOUseCase && !isNewDMO
              ? {
                  parent_dmo_account_id: DMO,
                  business_description: describe,
                }
              : null,
          unverified_dmo_account:
            isDMOUseCase && isNewDMO
              ? {
                  business_description: describe,
                }
              : null,
          properties: {
            'webapp.default-story-details': {
              ctaBtnText: 'Visit Us!',
              ctaBtnLinkUrl: transformedUrl,
            },
            'company-address': address,
          },
        };
        dispatch(finalizeAccount(options));

        // dispatch(
        //   patchVenue({
        //     id: account.id,
        //     update: {
        //       websiteUrl: transformedUrl,
        //       businessAddress: businessAdress,
        //       setupCompleted: true,
        //       dmo_member: {
        //         parent_dmo_account_id: DMO,
        //         business_description: describe,
        //       },
        //     },
        //   }),
        // );
        if (!isActiveCampaign) {
          createFirstCampaign(account.id, transformedUrl);
          setIsActiveCampaign(true);
        }

        dispatch(setSelectedVenue({ id: account.id }));
        dispatch(getVenue({ id: account.id }));
        // dispatch(
        //   patchVenueProperties({
        //     id: account.id,
        //     alert: false,
        //     update: {
        //       name: 'webapp.default-story-details',
        //       value: {
        //         ctaBtnText: 'Visit Us!',
        //         ctaBtnLinkUrl: transformedUrl,
        //       },
        //     },
        //   }),
        // );
        // dispatch(
        //   patchVenueProperties({
        //     id: account.id,
        //     alert: false,
        //     update: {
        //       name: 'company-address',
        //       value: address,
        //     },
        //   }),
        // );
        if (isMobile) {
          history.push(UnprotectedRoutes.MobileMoblockAccess);
        } else {
          dispatch(getProperties({ id: account.id })).then(() => {
            history.push(WebsiteRoutes.QuickStartWelcome);
          });
        }
      }
    };

    if (auth && items && items.length && accountName && !isSVAdmin && !isActiveCampaign) {
      CompleteProfileAction();
    }
  }, [
    items,
    accountName,
    auth,
    businessAdress,
    transformedUrl,
    dispatch,
    history,
    address,
    createDefaultCampaign,
    isSVAdmin,
    createFirstCampaign,
    setIsActiveCampaign,
    isActiveCampaign,
    DMO,
    describe,
  ]);

  // useEffect(() => {
  //   if (isSVAdmin && isEnterPrisePlan) {
  //     const a = document.createElement('a');
  //     a.href = `${origin}/signup/success`;
  //     a.click();
  //   }
  // }, [isSVAdmin, origin, isEnterPrisePlan]);

  const phoneValidationRef = useRef<PhoneValidationState>(VALID_PHONE_VALIDATION_STATE);

  // const [countryCode, setCountryCode] = useState('');
  // console.log(countryCode);

  const [firstName, lastName] = customerName ? customerName.split(' ') : ['', ''];

  const resetPasswordAction = async (resetPasswordValues: { password: string; email: string }) => {
    if (token) {
      try {
        const ResetPasswordResponse = await ResetPassword({
          token,
          newPassword: resetPasswordValues.password,
        });

        if (ResetPasswordResponse.success) {
          dispatch(
            authRequest({
              username: resetPasswordValues.email,
              password: resetPasswordValues.password,
            }),
          );
        }
      } catch (error) {
        addToast(LoginPageStrings.InvalidToken, { appearance: 'error', autoDismissTimeout: 25000 });
      }
    }
  };

  const initialValues = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    password: '',
    confirmedPassword: '',
    phone: phone?.substring(1) || '',
    address: '',
    city: '',
    state: '',
    zip: '',
    signature: '',
    companyName: accountName,
    practiceName: '',
    companyWebsite: '',
    terms: true,
    planCode: '',
    dmo: '',
    checked: true,
    describe: '',
  };

  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    touched,
    handleBlur,
    isValid: isFormValid,
    setFieldError,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: isSVAdmin
      ? getSVAdminValidationSchemaCompleteProfileForm({ phoneValidationRef })
      : getValidationSchemaCompleteProfileForm({ phoneValidationRef }),
    onSubmit: async (values) => {
      trackEvent(SignUpEventNames.submitButtonClicked, {
        //@ts-ignore
        value: values,
      });
      const url = castURLToHttps(values.companyWebsite);
      const addrss = `${values.address} ${values.city}, ${values.state} ${values.zip}`;
      setBusinessAdress(addrss);
      setTransformedUrl(url);
      if (values.dmo) {
        setDMO(DMOOptions.find((item) => item.value === values.dmo)?.id || '');
      }
      if (values.describe) {
        setDescribe(values.describe);
      }
      setIsEnabled(false);
      setAddress({
        address: values.address,
        state: values.state,
        zip: values.zip,
        city: values.city,
      });
      if (isSVAdmin) {
        const selectedDMO = DMOOptions.find((item) => item.value === values.dmo);
        const isNewDmo = selectedDMO && selectedDMO.id === NEW_DMO_ID;
        const isDMOUseCase = industry === TOURISM_INDUSTRY;
        const options = {
          id: id,
          websiteUrl: url,
          businessAddress: addrss,
          dmo_member:
            isDMOUseCase && !isNewDmo
              ? {
                  parent_dmo_account_id:
                    DMOOptions.find((item) => item.value === values.dmo)?.id || '',
                  business_description: values.describe,
                }
              : null,
          unverified_dmo_account:
            isDMOUseCase && isNewDmo
              ? {
                  business_description: values.describe,
                }
              : null,
          properties: {
            'webapp.default-story-details': {
              ctaBtnText: 'Visit Us!',
              ctaBtnLinkUrl: url,
            },
            'company-address': {
              address: values.address,
              state: values.state,
              zip: values.zip,
              city: values.city,
            },
          },
        };
        dispatch(finalizeAccount(options));

        // dispatch(
        //   patchVenue({
        //     id: id,
        //     update: showDMOStuff
        //       ? {
        //           websiteUrl: url,
        //           businessAddress: addrss,
        //           setupCompleted: true,
        //           dmo_member: {
        //             parent_dmo_account_id:
        //               DMOOptions.find((item) => item.value === values.dmo)?.id || '',
        //             business_description: values.describe,
        //           },
        //         }
        //       : {
        //           websiteUrl: url,
        //           businessAddress: addrss,
        //           setupCompleted: true,
        //         },
        //   }),
        // );
        createDefaultCampaign(id, url);
        dispatch(setSelectedVenue({ id: id }));
        dispatch(getVenue({ id: id }));
        // dispatch(
        //   patchVenueProperties({
        //     id: id,
        //     alert: false,
        //     update: {
        //       name: 'webapp.default-story-details',
        //       value: {
        //         ctaBtnText: 'Visit Us!',
        //         ctaBtnLinkUrl: transformedUrl,
        //       },
        //     },
        //   }),
        // );
        // dispatch(
        //   patchVenueProperties({
        //     id: id,
        //     alert: false,
        //     update: {
        //       name: 'company-address',
        //       value: address,
        //     },
        //   }),
        // );
        dispatch(getProperties({ id: id })).then(() => {
          history.push(WebsiteRoutes.QuickStartWelcome);
        });
      } else {
        resetPasswordAction({ email: values.email || '', password: values.password });
      }
    },
  });

  const { handleChangeWithTracking } = useHandleChangeWithTracking(handleChange);

  useEffect(() => {
    const el: HTMLInputElement | null = document.querySelector('.Mui-error');
    (el?.parentElement ?? el)?.scrollIntoView({ behavior: 'smooth' });
  }, [isSubmitting]);

  const getLabelWithDescription = (title: string, description: string) => {
    return (
      <Typography display="inline">
        {title}
        <Typography display="inline" color="#8B89A0 !important">
          {description}
        </Typography>
      </Typography>
    );
  };

  const getFieldError = (field: keyof typeof initialValues) => {
    return touched[field] ? errors[field] : undefined;
  };

  const showCheckBox = DMOOptions.find((item) => item.value === values.dmo);
  const showDMOStuff = industry === TOURISM_INDUSTRY;

  return (
    <Grid
      container
      sx={{ margin: '0 auto', height: '100vh' }}
      style={{
        width: '100%',
        backgroundColor: '#ffff',
        height: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
        backgroundPosition: ' 0px -300px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        zoom: 1.1,
      }}
    >
      {isMobile && (
        <Grid item xs={12}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              background: '#1E0035',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '40px',
              padding: '40px 20px',
            }}
          >
            <SocialVenuLogo />
          </div>
        </Grid>
      )}
      <Grid
        item
        xs={isMobile ? 12 : 6.5}
        style={{
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} className={isMobile ? classes.mobileMainSideComplete : classes.mainSide}>
          <Grid xs={12} className={classes.content}>
            <Typography className={classes.title} component="h1">
              {SingUpPage.AccountSetup}
            </Typography>
            <Typography fontSize={isMobile ? '16px' : '12px'} color="#667085" mb="10px">
              {SingUpPage.CompleteAccount}
            </Typography>
            <form onSubmit={handleSubmit} className={classes.form}>
              <Box mt="15px">
                <Typography fontSize={isMobile ? '16px' : '12px'} color="black" mb="10px">
                  {SingUpPage.CompanyName}
                </Typography>
                <Typography
                  fontSize={isMobile ? '16px' : '12px'}
                  color="black"
                  mb="10px"
                  marginLeft={isMobile ? '0px' : '10px'}
                  fontWeight={isMobile ? 600 : 500}
                >
                  {accountName}
                </Typography>
              </Box>
              <Box mt="15px">
                <Typography fontSize={isMobile ? '16px' : '12px'} color="black" mb="10px">
                  {SingUpPage.Owner}
                </Typography>
                <Typography
                  fontSize={isMobile ? '16px' : '12px'}
                  color="black"
                  mb="10px"
                  marginLeft={isMobile ? '0px' : '10px'}
                >
                  {customerName}
                </Typography>
                <Typography
                  fontSize={isMobile ? '16px' : '12px'}
                  color="black"
                  mb="10px"
                  marginLeft={isMobile ? '0px' : '10px'}
                >
                  {email} <span style={{ color: '#667085' }}> {SingUpPage.LoginUserName}</span>
                </Typography>
                <Typography
                  fontSize={isMobile ? '16px' : '12px'}
                  mb="10px"
                  marginLeft={isMobile ? '0px' : '10px'}
                >
                  {phone}
                </Typography>
              </Box>
              <Box mt="15px">
                <Typography fontSize={isMobile ? '16px' : '12px'} color="black" mb="10px">
                  {SingUpPage.CompanyWebsite}
                </Typography>
                <URLTextField
                  value={values.companyWebsite}
                  name="companyWebsite"
                  onChange={handleChangeWithTracking}
                  errorText={getFieldError('companyWebsite')}
                  placeholder={SingUpPage.SvWebsitePlaceholder}
                  onBlur={handleBlur}
                  fullWidth
                  className={isMobile ? classes.inputClassName : ''}
                />
              </Box>

              <Box mt="15px">
                <PlaceAutoComplete
                  label={SingUpPage.CompanyAddress}
                  value={`${values.address}`}
                  onChange={handleChangeWithTracking}
                  onSelect={({ address, city, state, zip }) => {
                    if (!address || !city || !state || !zip) {
                      setFieldError('address', SingUpPage.AddressError);
                      return;
                    }
                    setFieldValue('address', address);
                    setFieldValue('city', city);
                    setFieldValue('state', state);
                    setFieldValue('zip', zip);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                    if (errors.address) setFieldError('address', SingUpPage.AddressError);
                  }}
                  placeholder={SingUpPage.FirstLineOfAddress}
                  error={getFieldError('address')}
                />
              </Box>
              <Grid container justifyContent="space-between" alignItems={'flex-start'}>
                <Grid item xs={isMobile ? 12 : 4.7}>
                  <CustomTextField
                    inputClassName={isMobile ? classes.inputClassName : ''}
                    label={''}
                    value={values.city}
                    name="city"
                    handleChange={handleChangeWithTracking}
                    error={getFieldError('city')}
                    placeholder={SingUpPage.City}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={isMobile ? 6 : 4.1}>
                  <CustomSelectWithTextField
                    className={[classes.full, classes.inputClassName].join(' ')}
                    name={'state'}
                    value={values.state}
                    label={''}
                    onChange={handleChange}
                    options={states}
                    setFieldValue={setFieldValue}
                    optional={false}
                    initialValue="State"
                    onBlur={handleBlur}
                    height={isMobile ? '60px' : '43px'}
                    margin="0"
                    error={getFieldError('state')}
                  />
                </Grid>
                <Grid item xs={isMobile ? 6 : 2.9}>
                  <CustomTextField
                    inputClassName={isMobile ? classes.inputClassName : ''}
                    label={''}
                    value={values.zip}
                    name="zip"
                    handleChange={(event) => {
                      const val = event.target.value;
                      if ((/^\d+$/.test(val) || val === '') && val.length < 6) {
                        handleChangeWithTracking(event);
                      }
                    }}
                    error={getFieldError('zip')}
                    placeholder={SingUpPage.ZipCode}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>

              {!isSVAdmin && (
                <>
                  <CustomTextField
                    label={getLabelWithDescription(SingUpPage.Password, SingUpPage.LoginPassword)}
                    value={values.password}
                    name="password"
                    handleChange={(event) => {
                      handleChangeWithTracking(event, true);
                    }}
                    error={getFieldError('password')}
                    labelClassName={[
                      classes.marginTop,
                      isMobile ? classes.labelClassName : '',
                    ].join(' ')}
                    placeholder={SingUpPage.Enter + ' ' + SingUpPage.Password}
                    isPasswordField
                    onBlur={handleBlur}
                    autoComplete="new-password"
                    inputClassName={isMobile ? classes.inputClassName : ''}
                  />
                  <CustomTextField
                    label={SettingsPageStrings.ConfirmPassword}
                    name="confirmedPassword"
                    isPasswordField={true}
                    value={values.confirmedPassword}
                    handleChange={(event) => {
                      handleChangeWithTracking(event, true);
                    }}
                    error={getFieldError('confirmedPassword')}
                    labelClassName={[
                      classes.marginTop,
                      isMobile ? classes.labelClassName : '',
                    ].join(' ')}
                    placeholder={SettingsPageStrings.EnterPassword}
                    onBlur={handleBlur}
                    autoComplete="new-password"
                    inputClassName={isMobile ? classes.inputClassName : ''}
                  />
                </>
              )}
              {showDMOStuff && (
                <Box marginTop="8px">
                  <CustomSelectWithTextField
                    className={[classes.full, classes.inputClassName, classes.marginBottom].join(
                      ' ',
                    )}
                    name={'dmo'}
                    value={values.dmo}
                    labelClassName={
                      isMobile ? classes.MobileDMOLabelClassName : classes.DMOLabelClassName
                    }
                    label={'Are you a member of a DMO?'}
                    onChange={handleChange}
                    options={DMOOptions}
                    setFieldValue={setFieldValue}
                    optional={false}
                    initialValue="Choose..."
                    onBlur={handleBlur}
                    height={isMobile ? '60px' : '43px'}
                    margin="0"
                    error={getFieldError('state')}
                  />
                </Box>
              )}

              {showCheckBox?.id && (
                <Box style={{ marginTop: '20px' }} className={classes.termsBlock}>
                  <FormControlLabel
                    label={
                      <Typography
                        fontSize={isMobile ? '16px' : '12px'}
                        color="black"
                        marginTop="10px"
                        mb="10px"
                        fontWeight={isMobile ? 600 : 500}
                      >
                        {'I agree that my DMO can utilize my guest videos for marketing purposes.'}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={values.checked}
                        name="checked"
                        onChange={(event) => {
                          // handleChange(event);
                          trackEvent(SignUpEventNames.DMOCheckBoxClicked, {
                            value: event.target.checked,
                          });
                        }}
                        size="medium"
                      />
                    }
                  />
                </Box>
              )}

              {showDMOStuff && (
                <CustomSelectWithTextField
                  className={[classes.full, classes.inputClassName, classes.marginBottom2x].join(
                    ' ',
                  )}
                  name={'describe'}
                  value={values.describe}
                  labelClassName={
                    isMobile
                      ? [classes.MobileDMOLabelClassName, classes.marginTop1x].join(' ')
                      : [classes.DMOLabelClassName, classes.marginTop1x].join(' ')
                  }
                  label={'Describe your business:'}
                  onChange={handleChange}
                  options={DescribeBusinessOptions}
                  setFieldValue={setFieldValue}
                  optional={false}
                  initialValue="Choose..."
                  onBlur={handleBlur}
                  height={isMobile ? '60px' : '43px'}
                  margin="0"
                  error={getFieldError('state')}
                />
              )}

              {/* <Signature
                signature={values.signature}
                onChange={handleChangeWithTracking}
                error={getFieldError('signature')}
                clientName={`${values.firstName} ${values.lastName}`}
                onBlur={handleBlur}
              /> */}

              <Box className={classes.SubmitBtn}>
                <CustomButton
                  className={isMobile ? classes.mobileSubmitBtn : undefined}
                  title="Save"
                  type="submit"
                  colorType="primary"
                  size="large"
                  fullWidth
                  disabled={!isEnabled || (!isFormValid && isEnabled)}
                  endIcon={<ArrowForwardIosRoundedIcon />}
                />
              </Box>
            </form>
          </Grid>
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid item xs={5.5}>
          <Box
            sx={{
              height: '100%',
              background: '#000000',
              position: 'relative',
            }}
          >
            <PlanBackgroundTopIcon />
            <Box className={classes.BackgroundSideIcon}>
              <PlanBackgroundSideIcon />
            </Box>
            <Box position="sticky" top="50px">
              <>
                <PricingPlanPreview
                  plan={SVenterPrisePlan}
                  referralPlan={SVenterPrisePlan}
                  isTrubluPlan={false}
                  isEnterPrisePlan={false}
                  isCompleteProfile={true}
                  showBulletPoints={true}
                />
              </>
            </Box>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
