export interface PricingPlanModel {
  id: string;
  name: string;
  code: string;
  distributorId: string;
  subscriptionFee: string;
  billingPeriod: string;
  setupFee: string;
  position: number;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  lastModifiedBy?: string;
  details?: {
    displayName?: string;
    promoText?: string;
  };
  upfrontFeeMonths?: number;
  upfrontFee?: string;
  customerSupportTier?: string;
  industry?: string;
  categoryId: string;
  contractTermMonths: number | null;
}

export const TOURISM_INDUSTRY = 'TOURISM';

export interface PricingPlanCategories {
  id: string;
  name: string;
  createdAt: string;
  deletedAt: string | null;
  imageUrl: string | null;
  position: number;
  updatedAt: string | null;
  industry: string | null;
}

export interface GetPricingPlansRequest {
  distributorId: string;
}
export interface GetNewPricingPlansRequest {
  categoryId: string;
}

export type GetPricingPlansResponse = Array<PricingPlanModel>;

export interface GetPricingPlanByIdOrCodeRequest {
  idOrCode: string;
  token?: boolean;
}
export type GetPricingPlanByIdOrCodeResponse = PricingPlanModel;

export interface GetPricingPlanByCodeRequest {
  code: string;
}
export type GetPricingPlanByCodeResponse = PricingPlanModel;

export interface PostPricingPlanRequest {
  payload: Pick<
    PricingPlanModel,
    'name' | 'code' | 'distributorId' | 'subscriptionFee' | 'setupFee' | 'billingPeriod'
  >;
}
export type PostPricingPlanResponse = PricingPlanModel;

export interface PutPricingPlansRequest {
  id: PricingPlanModel['id'];
  payload: Partial<Pick<PricingPlanModel, 'name' | 'code' | 'subscriptionFee' | 'setupFee'>>;
}
export type PutPricingPlanResponse = PricingPlanModel;
