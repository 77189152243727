import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ClientTypes } from '../../../api/models/common';
import { LogoPresentation, VenueProperties, WebAppSignUpConfig } from '../../../api/models/venue';
import { RegisterVenueRequest } from '../../../api/models/venueCreation';
import { SV_DEFAULT_INCENTIVE_IMAGE } from '../../../common/constants/constants';
import {
  DEFAULT_BRAND_COLOR,
  DEFAULT_REWARD_SUBTITLE,
  DEFAULT_REWARD_TITLE,
  DEFAULT_STORY_CTA_BUTTON,
} from '../../../common/constants/defaults';
// import { useFTE } from '../../hooks/useFTE';
import { useAppDispatch, useTypedSelector } from '../../../store';
// import { authRequest, getUserAccounts } from '../../store/slices/meSlice';
import { RegisterVenue, getAccountCreationIntent } from '../../../store/slices/venueCreationSlice';
// import { setSelectedVenue } from '../../store/slices/venueSlice';
// import { CongratsModal } from './CongratsModal';
import { useStyles } from '../signupSuccess.helper';
import { SocialVenuLogo } from '../../../common/assets/newDesign/Sidebar/SocialVenuLogo';
import { Box, Button } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { AccountCreationIntent } from '../../../store/storeModels';
import { getPricingPlanByReferralCode } from '../../../store/slices/pricingPlansSlice';
import { PricingPlanModel } from '../../../api/models/pricingPlans';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// const APIresponse = {
//   account: {
//     id: '77879b16-8afb-45e7-aa31-18925f077b8e',
//     name: 'Account Signup PWD R Token',
//     subdomain: 'lwaoi',
//     clientType: 'MARKETING',
//     logo: '',
//     teamName: 'Account Signup PWD R Token',
//     launchedAt: null,
//     websiteUrl: 'https://',
//     industry: null,
//     planId: '717980a5-e97a-498a-b240-46d225f5bb48',
//     refId: null,
//     billingAccountId: '83fbbc18-4d8f-4837-914c-73ba0ec047d7',
//     businessAddress: ' ,  ',
//     active: true,
//     deactivatedAt: null,
//     deactivatedBy: null,
//     billable: true,
//     setupCompleted: true,
//     tutorialCompleted: null,
//     createdAt: '2024-10-23T11:14:27.562252490Z',
//     updatedAt: '2024-10-23T11:14:27.562252631Z',
//     createdBy: null,
//     lastModifiedBy: null,
//   },
//   token: '3tii4IirG3Thhi9ioqw7288gGNFn6fHJ',
// };

export const StripeFlowWrapper = () => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isLoading, created, error, payload } = useTypedSelector(
    (state) => state.venueCreation.createdVenue,
  );
  const checkoutSessionId = query.get('session_id');
  const intentId = query.get('intent_id');
  const formData = localStorage.getItem('signupForm');
  const formValues = formData ? JSON.parse(formData) : {};
  const industry = localStorage.getItem('industry');
  const [queryStrings, setQueryStrings] = useState('');
  const {
    isLoading: isIntentLoading,
    data: intentData,
    hasLoaded: hasIntentLoaded,
  } = useTypedSelector((state) => state.venueCreation.Intent);
  const { value: activePricingPlan } = useTypedSelector((state) => state.pricingPlans.planDetails);

  useEffect(() => {
    if (intentData) {
      dispatch(getPricingPlanByReferralCode({ code: intentData.pricingPlanCode }));
    }
  }, [intentData, dispatch]);

  useEffect(() => {
    if (intentId && !isIntentLoading && !hasIntentLoaded) {
      dispatch(getAccountCreationIntent({ id: intentId }));
    }
  }, [intentId, isIntentLoading, hasIntentLoaded, dispatch]);

  useEffect(() => {
    if (queryStrings) {
      localStorage.setItem('signupForm', '');
    }
  }, [queryStrings]);

  const logoUrl = '';

  const ctaBtnText = `Visit ${
    formValues?.practiceName ? formValues.practiceName : formValues?.companyName
  }`;

  const getBaseRegisterVenueRequestBody = (
    intent: AccountCreationIntent,
    plan: PricingPlanModel,
  ) => {
    return {
      businessAddress: null,
      name: intent.companyName,
      websiteUrl: '',
      referralCode: intent.pricingPlanCode || 'BASIC',
      industry: plan.industry || industry || null,
      soldBy: intent.soldBy || undefined,
      clientType: intent.clientType || ClientTypes.MARKETING,
      managerAccount: {
        email: intent.email || '',
        firstName: intent.firstName || '',
        lastName: intent.lastName || '',
        password: '',
        phoneNumber: intent.phoneNumber || '',
      },
      logoUrl: logoUrl,
      properties: {
        onboarding_attributes: {},
        'webapp.config': {
          'logo-url': logoUrl,
          'primary-color': DEFAULT_BRAND_COLOR,
          'filter-logo-enabled': true,
        },
        'webapp.cards-welcome': {
          mobile: {
            original: '',
            cropped: '',
          },
          tablet: {
            original: '',
            cropped: '',
          },
          desktop: {
            original: '',
            cropped: '',
          },
          title: '',
          subtitle: '',
        },
        'webapp.incentive.config': {
          title: DEFAULT_REWARD_TITLE,
          action: DEFAULT_REWARD_SUBTITLE,
          imageUrl: SV_DEFAULT_INCENTIVE_IMAGE,
          points: 100,
          activities: [
            { activityType: 'CREATE_VIDEO', points: 25, status: true },
            { activityType: 'SHARE', points: 10, status: true },
            { activityType: 'CALL_TO_ACTION', points: 50, status: true },
            { activityType: 'BONUS_POINTS', points: 100, status: true },
          ],
        },
        'webapp.default-story-details': {
          ctaBtnText: ctaBtnText.length > 30 ? DEFAULT_STORY_CTA_BUTTON : ctaBtnText,
          ctaBtnLinkUrl: '',
        },
        'webapp.signUp.config': {
          phoneRequired: true,
          nameRequired: true,
          emailRequired: false,
          logoPresentation: LogoPresentation.Default,
          showCompanyName: true,
        } as WebAppSignUpConfig,
        'dashboard.config': {
          dashboardLogo: logoUrl,
        },
        'dashboard.QRCode.config': {
          logo: logoUrl,
          brandColor: DEFAULT_BRAND_COLOR,
        },
        legal_name: intent.companyName,
        'company-address': {
          address: '',
          city: '',
          state: '',
          zip: '',
        } as VenueProperties['company-address'],
        'quickstart.config': {
          currentRoute: '/quickstart/welcome',
          isQuickStartInProgress: true,
        },
      },
    };
  };

  //https://storage.googleapis.com/sv-dev-assets/14eef41f-8001-4c43-9a02-838dfecdf837/7b92f74f-2ff5-47ab-844a-838ff8550b12.jpg

  useEffect(() => {
    if (
      checkoutSessionId &&
      !isLoading &&
      !created &&
      hasIntentLoaded &&
      activePricingPlan &&
      intentData
    ) {
      const baseRegisterVenueRequestBody = getBaseRegisterVenueRequestBody(
        intentData,
        activePricingPlan,
      );
      const registerVenueRequestBody: RegisterVenueRequest = {
        ...baseRegisterVenueRequestBody,
        stripCheckoutSessionId: checkoutSessionId || '',
        quickFormRegistration: true,
      };

      dispatch(RegisterVenue(registerVenueRequestBody)).then((response) => {
        if (response && response.payload) {
          const firstName = encodeURIComponent((formValues?.firstName as string) || '');
          const lastName = encodeURIComponent((formValues?.lastName as string) || '');
          const customerName = encodeURIComponent(firstName + ' ' + lastName);
          const accountName = encodeURIComponent(
            (formValues?.practiceName as string) || (formValues?.companyName as string) || '',
          );
          const email = encodeURIComponent((formValues?.email as string) || '');
          const phoneNumber = encodeURIComponent((formValues?.phone as string) || '');
          const token = encodeURIComponent(response.payload.token);
          const accountId = encodeURIComponent(response.payload.account.id);
          const queryStrings = `token=${token}&accountId=${accountId}&customerName=${customerName}&accountName=${accountName}&email=${email}&phoneNumber=${phoneNumber}`;
          console.log('here after dispatch 1', queryStrings);
          setQueryStrings(queryStrings);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutSessionId, dispatch, hasIntentLoaded, activePricingPlan, intentData]);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);
  ///complete-profile?token=EV0Aja1wmo3IuEJ3dtwVkg1I2hw7N1xd&customerName=Yassine+AB&accountName=Account+Signup+update&email=yassine%2Baccountsignupupdate%40socialvenu.com&phoneNumber=%2B19783491570
  const handleOnCompleteSetupClick = () => {
    if (payload && payload.token) {
      history.push(`/complete-profile?${queryStrings}`);
    }
  };
  //   if (!isSVAdmin && !checkoutSessionId) {
  //     return null;
  //   }

  /*  const handleContactUsClick = () => {
    const a = document.createElement('a');
    a.href = `mailto:${ContactUsString.SupportEmail1}?cc=${ContactUsString.SupportEmail2}&subject=${ContactUsString.ErrorSignUp}`;
    a.click();
  };*/

  return (
    <>
      <div className={classes.QuicksignUpWrapper}>
        <Box
          style={
            isMobile
              ? {
                  borderRadius: '15px',
                  background: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '112px 40px 82px 40px',
                  margin: '40px',
                  width: '408px',
                  marginTop: '-500px',
                  scale: '1.4',
                }
              : {
                  borderRadius: '15px',
                  background: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '112px 33px 49px 35px',
                  margin: '40px',
                  width: '598px',
                  height: '408px',
                  marginTop: '-300px',
                }
          }
        >
          <div style={{ marginBottom: '20px', marginTop: '-100px' }}>
            <SocialVenuLogo />
          </div>
          <p className={classes.boldparagraph}>Welcome to SocialVenu!</p>
          <p
            className={[
              classes.paragraph,
              isLoading ? classes.paragraphLoading : classes.paragraphLoaded,
            ].join(' ')}
          >
            Your account is being created...
          </p>
          <p className={classes.boldparagraph}>
            Click below to complete account setup, create a password and begin customizing to your
            business.
          </p>
          <div>
            <Button
              color="primary"
              variant="contained"
              className={classes.completePorfileButton}
              disabled={isLoading || error}
              onClick={handleOnCompleteSetupClick}
            >
              Complete Account Setup
            </Button>
          </div>
        </Box>
      </div>
    </>
  );
};
